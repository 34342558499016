<template>
  <div class="text-center">
    <v-dialog v-model="show" width="500">
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-toolbar color="blue" dark>
            <v-toolbar-title> {{ $root.currentMessages.eventDetails }} </v-toolbar-title>
          </v-toolbar>
          <v-text-field
            :label=$root.currentMessages.title
            :value="field.title"
            class="pt-6 ma-2 mx-lg-auto"
            @input="updateVariable($event)"
            :rules="[v => !!v || $root.currentMessages.itemIsRequired]"
            style="padding-left: 5px;"
            centered
            required
            :disabled="field.disableEdit"
          ></v-text-field>
          <v-row class="pt-6 ma-2 mx-lg-auto">
            <v-menu v-model="menuDates" :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="field.dates[0]"
                  :label=$root.currentMessages.startDate
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[v => !!v || $root.currentMessages.itemIsRequired]"
                  required
                  :disabled="field.disableEdit"
                ></v-text-field>
                <v-text-field
                  v-model="field.dates[1]"
                  :label=$root.currentMessages.endDate
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[v => !!v || $root.currentMessages.itemIsRequired]"
                  required
                  :disabled="field.disableEdit"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="field.dates"
                :locale=$root.currentMessages.dateFormat
                :first-day-of-week="1"
                @input="onChangeDate"
                color="blue"
                range
              >
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="menuDates = false"
                  text
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-row>
            <v-switch
            v-model="field.isTimed"
            :label=$root.currentMessages.addTime
            class="ma-4"
            :disabled="field.disableEdit"
          ></v-switch>
          <v-row v-if="field.isTimed" class="pt-6 ma-2 mx-lg-auto">
            <v-menu v-model="menuStartTime" :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="field.times[0]"
                  :label=$root.currentMessages.startTime
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[v => !!v || $root.currentMessages.itemIsRequired]"
                  :disabled="field.disableEdit"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menuStartTime"
                v-model="field.times[0]"
                format="24hr"
                @input="onChangeTime"
              >
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="menuStartTime = false"
                  text
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-menu>
            <v-menu v-model="menuEndTime" :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="field.times[1]"
                  :label=$root.currentMessages.endTime
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[v => !!v || $root.currentMessages.itemIsRequired]"
                  :disabled="field.disableEdit"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menuEndTime"
                v-model="field.times[1]"
                format="24hr"
                @input="onChangeTime"
              >
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="menuEndTime = false"
                  text
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-menu>
          </v-row>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="createEvent"
            v-if="field.newEvent"
            :disabled="!valid"
          >
            {{ $root.currentMessages.create }}
          </v-btn>
          <v-btn
            depressed
            color="primary"
            @click="updateEvent"
            v-if="!field.newEvent && !field.disableEdit"
          >
            {{ $root.currentMessages.update }}
          </v-btn>
          <v-btn
            color="error"
            @click="removeEvent"
            v-if="!field.newEvent && !field.disableEdit"
          >
            {{ $root.currentMessages.delete }}
          </v-btn>
          <v-card-text class="pa-6 text-center" v-if="!field.newEvent && field.disableEdit">
            {{ field.participant.title }} {{ $root.currentMessages.wrongUserSelected }} {{ field.currentParticipant }}.
          </v-card-text>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
  import { Timestamp } from "firebase/firestore";
  import { getSelectedParticipant } from "../../models/participants.js";
  import { createEvent, removeEvent, updateEvent } from "../../models/events.js"


  export default {
    props:  {
     value: Boolean,
     fields: Object,
   },
   computed: {
     show: {
       get () { return this.value },
       set (value) { this.$emit('input', value)}
     }
   },
    data () {
      return {
        valid: true,
        field: this.fields,
        menuDates: false,
        menuStartTime: false,
        menuEndTime: false,
      }
    },
    created() {
      this.$emit("fields", this.field);
    },
    methods: {
      async createEvent () {
        var eventData = this.getEventData("id" + Date.now());
        if (!eventData) return;
        const participant = getSelectedParticipant().title;
        createEvent(participant, eventData);
        this.show = false;
      },
      async removeEvent(){
          const participant = getSelectedParticipant().title;
          removeEvent(participant, this.field.id);
          this.show = false;
      },
      async updateEvent(){
        var updateData = this.getEventData(this.field.id);
        if (!updateData) return;
        const participant = getSelectedParticipant().title;
        updateEvent(participant, updateData);
        this.show = false;
      },
      updateVariable(value){
        this.field.title = value;
      },
      onChangeDate(){
        if (new Date(this.field.dates[0]) > new Date(this.field.dates[1])) {
          this.field.dates.reverse();
          this.field.times = [undefined, undefined];
        }
      },
      onChangeTime(){
        if (this.field.dates[0] && this.field.dates[1] && this.field.dates[0] === this.field.dates[1] && this.field.times[0] > this.field.times[1])
        this.field.times.sort();
      },
      getEventData(idEvent){
        if((this.field.dates.length != 2 || !this.field.dates[0] || !this.field.dates[1]) || (this.field.isTimed && (this.field.times.length != 2 || !this.field.times[0] || !this.field.times[1] ))){
          this.$refs.form.validate();
          return null;
        }

        let startDate = new Date(this.field.dates[0]);
        let endDate = new Date(this.field.dates[1]);
        if(this.field.isTimed){
          var [hours, minutes] = this.field.times[0].split(':').map(Number);
          startDate.setHours(hours);
          startDate.setMinutes(minutes);
          [hours, minutes] = this.field.times[1].split(':').map(Number);
          endDate.setHours(hours);
          endDate.setMinutes(minutes);
        }

        const eventData = { 
          [idEvent]: { 
            title: this.field.title,
            startDate: Timestamp.fromDate(startDate),
            endDate: Timestamp.fromDate(endDate),
            isTimed: this.field.isTimed,
          } 
        };
        return eventData;
      }
    }
  }
</script>
