var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"blue","dark":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$root.currentMessages.eventDetails)+" ")])],1),_c('v-text-field',{staticClass:"pt-6 ma-2 mx-lg-auto",staticStyle:{"padding-left":"5px"},attrs:{"label":_vm.$root.currentMessages.title,"value":_vm.field.title,"rules":[function (v) { return !!v || _vm.$root.currentMessages.itemIsRequired; }],"centered":"","required":"","disabled":_vm.field.disableEdit},on:{"input":function($event){return _vm.updateVariable($event)}}}),_c('v-row',{staticClass:"pt-6 ma-2 mx-lg-auto"},[_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$root.currentMessages.startDate,"prepend-icon":"mdi-calendar","readonly":"","rules":[function (v) { return !!v || _vm.$root.currentMessages.itemIsRequired; }],"required":"","disabled":_vm.field.disableEdit},model:{value:(_vm.field.dates[0]),callback:function ($$v) {_vm.$set(_vm.field.dates, 0, $$v)},expression:"field.dates[0]"}},'v-text-field',attrs,false),on)),_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$root.currentMessages.endDate,"prepend-icon":"mdi-calendar","readonly":"","rules":[function (v) { return !!v || _vm.$root.currentMessages.itemIsRequired; }],"required":"","disabled":_vm.field.disableEdit},model:{value:(_vm.field.dates[1]),callback:function ($$v) {_vm.$set(_vm.field.dates, 1, $$v)},expression:"field.dates[1]"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDates),callback:function ($$v) {_vm.menuDates=$$v},expression:"menuDates"}},[_c('v-date-picker',{attrs:{"locale":_vm.$root.currentMessages.dateFormat,"first-day-of-week":1,"color":"blue","range":""},on:{"input":_vm.onChangeDate},model:{value:(_vm.field.dates),callback:function ($$v) {_vm.$set(_vm.field, "dates", $$v)},expression:"field.dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.menuDates = false}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-switch',{staticClass:"ma-4",attrs:{"label":_vm.$root.currentMessages.addTime,"disabled":_vm.field.disableEdit},model:{value:(_vm.field.isTimed),callback:function ($$v) {_vm.$set(_vm.field, "isTimed", $$v)},expression:"field.isTimed"}}),(_vm.field.isTimed)?_c('v-row',{staticClass:"pt-6 ma-2 mx-lg-auto"},[_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$root.currentMessages.startTime,"prepend-icon":"mdi-clock-time-four-outline","readonly":"","rules":[function (v) { return !!v || _vm.$root.currentMessages.itemIsRequired; }],"disabled":_vm.field.disableEdit},model:{value:(_vm.field.times[0]),callback:function ($$v) {_vm.$set(_vm.field.times, 0, $$v)},expression:"field.times[0]"}},'v-text-field',attrs,false),on))]}}],null,false,1790762053),model:{value:(_vm.menuStartTime),callback:function ($$v) {_vm.menuStartTime=$$v},expression:"menuStartTime"}},[(_vm.menuStartTime)?_c('v-time-picker',{attrs:{"format":"24hr"},on:{"input":_vm.onChangeTime},model:{value:(_vm.field.times[0]),callback:function ($$v) {_vm.$set(_vm.field.times, 0, $$v)},expression:"field.times[0]"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.menuStartTime = false}}},[_vm._v(" OK ")])],1):_vm._e()],1),_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$root.currentMessages.endTime,"prepend-icon":"mdi-clock-time-four-outline","readonly":"","rules":[function (v) { return !!v || _vm.$root.currentMessages.itemIsRequired; }],"disabled":_vm.field.disableEdit},model:{value:(_vm.field.times[1]),callback:function ($$v) {_vm.$set(_vm.field.times, 1, $$v)},expression:"field.times[1]"}},'v-text-field',attrs,false),on))]}}],null,false,1946774443),model:{value:(_vm.menuEndTime),callback:function ($$v) {_vm.menuEndTime=$$v},expression:"menuEndTime"}},[(_vm.menuEndTime)?_c('v-time-picker',{attrs:{"format":"24hr"},on:{"input":_vm.onChangeTime},model:{value:(_vm.field.times[1]),callback:function ($$v) {_vm.$set(_vm.field.times, 1, $$v)},expression:"field.times[1]"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.menuEndTime = false}}},[_vm._v(" OK ")])],1):_vm._e()],1)],1):_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),(_vm.field.newEvent)?_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.valid},on:{"click":_vm.createEvent}},[_vm._v(" "+_vm._s(_vm.$root.currentMessages.create)+" ")]):_vm._e(),(!_vm.field.newEvent && !_vm.field.disableEdit)?_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.updateEvent}},[_vm._v(" "+_vm._s(_vm.$root.currentMessages.update)+" ")]):_vm._e(),(!_vm.field.newEvent && !_vm.field.disableEdit)?_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.removeEvent}},[_vm._v(" "+_vm._s(_vm.$root.currentMessages.delete)+" ")]):_vm._e(),(!_vm.field.newEvent && _vm.field.disableEdit)?_c('v-card-text',{staticClass:"pa-6 text-center"},[_vm._v(" "+_vm._s(_vm.field.participant.title)+" "+_vm._s(_vm.$root.currentMessages.wrongUserSelected)+" "+_vm._s(_vm.field.currentParticipant)+". ")]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }