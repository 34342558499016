export default {
  eventDetails: 'Event Details',
  create: 'Create',
  update: 'Update',
  delete: 'Delete',
  close: 'Close',
  cancel: 'Cancel',
  andEvents: "and their events ?",
  participantDeletion: 'Participant Deletion',
  startDate: "Start Date",
  endDate: "End Date",
  title:"Title",
  dateFormat: "en-en",
  itemIsRequired: "Item is required",
  contactInformation: "Contact Us",
  name: "Name",
  email: "Email",
  phone: "Phone",
  createNewCalendar: "Create new calendar",
  participants: "Participants",
  load: "Load",
  myCalendars: "My Calendars",
  clickOnYourName: "Click on your name",
  copyToClipboard: "Copied to clipboard",
  calendarID: "Paste calendar URL here",
  calendarURL: "Calendar URL",
  shareCalendar: "Share Calendar",
  newParticipant: "New Participant",
  add: "Add",
  newParticipantName: "New Participant Name",
  newCalendarName:"New Calendar Name",
  invalidID: "ID is invalid",
  invalidCalendarDeleteMessage: "This calendar is not valid anymore.",
  emptyCalendarsText: "You don't seem to have any previous calendars. You should create or load one !",
  importCalendarText: "Ask your friends for the link of the calendar you want to join. Then just copy paste the link below.",
  loadCalendar: "Import calendar",
  atLeastOneParticipant: "At least one participant needs to be added.",
  invalidURL: "URL is invalid",
  urlMustBeFromApp: "The URL must be from the application",
  calendarIDNotEmpty: "The calendar ID can't be empty",
  shareCalendarText: "Join me on my shared calendar !",
  wrongUserSelected: " must be selected to edit this event but it is currently ",
  send: "Send",
  subject: "Subject",
  message: "Message",
  contactSuccessullySent: "Thanks for contacting us, your message was successfully sent.",
  addTime: "Add time?",
  startTime:"Start Time",
  endTime: "End Time"
}
