import { collection, addDoc } from "firebase/firestore";
import { db } from "../main.js";

const contactCollectionName = "Contact";

export async function sendContactForm({ name, email, subject, message }) {
  if (!name || !email || !subject || !message) {
    console.error("Missing required fields");
    return;
  }

  try {
    const contactCollection = collection(db, contactCollectionName);

    await addDoc(contactCollection, {
      name,
      email,
      subject,
      message,
      timestamp: new Date(),
    });
  } catch (error) {
    console.error("Error submitting contact form: ", error);
  }
}