<template>
  <v-dialog v-model="show" width="500">
      <v-card>
        <v-toolbar color="indigo" dark>
          <v-toolbar-title>{{ $root.currentMessages.contactInformation }}</v-toolbar-title>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="pa-6 text-center"  v-if="messageSent">
          {{ $root.currentMessages.contactSuccessullySent }}
        </v-card-text>
         <v-card-text v-if="!messageSent">
            <v-form ref="form">
              <v-text-field
                v-model="form.name"
                :label=$root.currentMessages.name
                :rules="[v => !!v || $root.currentMessages.itemIsRequired]"
                required
              ></v-text-field>
              <v-text-field
                v-model="form.email"
                :label=$root.currentMessages.email
                :rules="[v => !!v || $root.currentMessages.itemIsRequired]"
                type="email"
                required
              ></v-text-field>
              <v-text-field
                v-model="form.subject"
                :label=$root.currentMessages.subject
                :rules="[v => !!v || $root.currentMessages.itemIsRequired]"
                required
              ></v-text-field>
              <v-textarea
                v-model="form.message"
                :label=$root.currentMessages.message
                :rules="[v => !!v || $root.currentMessages.itemIsRequired]"
                rows="4"
                required
              ></v-textarea>
            </v-form>
          </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn color="primary" text @click="show = false" v-if="!messageSent">
              {{ $root.currentMessages.cancel }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="submitForm" v-if="!messageSent">
              {{ $root.currentMessages.send }}
            </v-btn>
            <v-btn color="primary" text @click="show = false" v-if="messageSent">
              {{ $root.currentMessages.close }}
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  <script>
      import { sendContactForm } from "../../models/contact.js"

      export default {
        data() {
          return {
           messageSent: false,
            form: {
              name: '',
              email: '',
              subject: '',
              message: '',
            },
          }
        },
        watch: {
          show(newVal) {
            if (newVal) {
              this.messageSent = false;
            }
          }
        },
        props: {
          value: Boolean,
        },
        computed: {
          show: {
            get() { return this.value },
            set (value) { this.$emit ('input', value) }
          }
        },
        methods: {
          submitForm() {
            if (!this.form.email || !this.form.subject || !this.form.subject || !this.form.message){
              this.$refs.form.validate();
              return;
            }

            sendContactForm(this.form);
            this.messageSent = true;
            this.form = { name: '', email: '', subject: '', message: '' };
          }
        }
      }
  </script>
